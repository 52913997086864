import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function RestaurantTopBar() {
    const [offers] = useState([
        { id: 1, message: "Get 15 % Off On orders above $45 - Use Code GET15" },
        { id: 2, message: "Free Delivery On orders above $100 - Use Code FREEDEL100" },
        { id: 3, message: "Get A Free Desert on all orders above $45 - Use Code FREEDESSERT" }])
    const offerDiscounts = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    return (
        <Col lg={12} className="px-0 restaurant-topbar-offers">
            <div className="restaurant-topbar text-center py-2">
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={offerDiscounts}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    arrows={false}
                >
                    {offers?.map((data) => {
                        return (
                            <>
                                <h6 className="mb-0 text-white text-center px-2">{data.message}</h6>
                            </>
                        )
                    })}
                </Carousel>
            </div>
        </Col>
    );
}

export default RestaurantTopBar