import Footer from "../Footer/Footer";
import CommonHomeNavbar from "../Navbar/CommonHomeNavbar";
import "./StaticPage.css";
import { PiListMagnifyingGlassLight } from "react-icons/pi";
import { Helmet } from "react-helmet";

function TermsCondition() {
  return (
    <>
    <CommonHomeNavbar />

    <Helmet>
        {/* meta tags */}
        <title>
        Terms & Conditions  | Masala of India | Indian Restaurant in Seattle
        </title>
        <meta
          name="description"
          content="Find out everything you need to know about our website's Terms and Conditions. Stay informed and make informed decisions."
        />
        <meta
          name="keywords"
          content="Terms & Conditions, Masala Of India, best indian restaurant in  seattle, indian cuisine in seattle, indian food lunch buffet in seattle, indian restaurants near me delivery, online indian food delivery near me, indian restaurants in seattle washington, best indian restaurants in seattle, indian buffet bellevue,indian food buffets, best indian restaurants in bellevu, indian restaurants in seattle"
        />

        {/* OG tags */}
        <meta
          property="og:title"
          content="Masala of India Cuisine Best Indian Restaurant in Seattle, WA 98125"
        />
        <meta
          property="og:description"
          content="Find out everything you need to know about our website's Terms and Conditions. Stay informed and make informed decisions."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.masalaofindia.com/terms-condition"
        />
        <meta
          property="og:image"
          content="https://www.masalaofindia.com/static/media/inner-banner.e315c31bfa6eb8d37bb1.jpg"
        />
        <meta property="og:site_name" content="masala of india" />

        {/* Twitter tags */}
        <meta
          name="twitter:title"
          content="Masala of India Cuisine Best Indian Restaurant in Seattle, WA 98125"
        />
        <meta
          name="twitter:description"
          content="Find out everything you need to know about our website's Terms and Conditions. Stay informed and make informed decisions."
        />
        <meta
          name="twitter:image"
          content="https://www.masalaofindia.com/static/media/inner-banner.e315c31bfa6eb8d37bb1.jpg"
        />
        <meta name="twitter:site" content="masala of india" />
      </Helmet>

    <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-header-box">
                <h1 class="text-anime">Terms & Conditions</h1>
                <nav>
                  <ol class="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Terms & Conditions</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid static-page">
        <div className="section-header mb-1">
          <h3 className="text-center">Terms & Conditions</h3>
        </div>
        <p className="text-center">
          <PiListMagnifyingGlassLight />
          No Data
        </p>
      </div>
      <Footer />
    </>
  );
}
export default TermsCondition;
