import { PiListMagnifyingGlassLight } from "react-icons/pi";
import Footer from "../Footer/Footer";
import "./StaticPage.css";
import CommonHomeNavbar from "../Navbar/CommonHomeNavbar";
import { Helmet } from "react-helmet";
function Disclaimer() {
  return (
    <>
      <CommonHomeNavbar />

      <Helmet>
        {/* meta tags */}
        <title>
        Disclaimer | Masala of India | Indian Restaurant in Seattle
        </title>
        <meta
          name="description"
          content="Disclaimer: Brief, clear, and concise information about content accuracy and user responsibility. Read before engaging with the material."
        />
        <meta
          name="keywords"
          content="Disclaimer, Masala Of India, best indian restaurant in  seattle, indian cuisine in seattle, indian food lunch buffet in seattle, indian restaurants near me delivery, online indian food delivery near me, indian restaurants in seattle washington, best indian restaurants in seattle, indian buffet bellevue,indian food buffets, best indian restaurants in bellevu, indian restaurants in seattle"
        />

        {/* OG tags */}
        <meta
          property="og:title"
          content="Disclaimer | Masala of India | Indian Restaurant in Seattle"
        />
        <meta
          property="og:description"
          content="Disclaimer: Brief, clear, and concise information about content accuracy and user responsibility. Read before engaging with the material."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.masalaofindia.com/disclaimer"
        />
        <meta
          property="og:image"
          content="https://www.masalaofindia.com/static/media/inner-banner.e315c31bfa6eb8d37bb1.jpg"
        />
        <meta property="og:site_name" content="masala of india" />

        {/* Twitter tags */}
        <meta
          name="twitter:title"
          content="Disclaimer | Masala of India | Indian Restaurant in Seattle"
        />
        <meta
          name="twitter:description"
          content="Disclaimer: Brief, clear, and concise information about content accuracy and user responsibility. Read before engaging with the material."
        />
        <meta
          name="twitter:image"
          content="https://www.masalaofindia.com/static/media/inner-banner.e315c31bfa6eb8d37bb1.jpg"
        />
        <meta name="twitter:site" content="masala of india" />
      </Helmet>

      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-header-box">
                <h1 class="text-anime">Disclaimer</h1>
                <nav>
                  <ol class="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Disclaimer</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid static-page">
        <h3 className="text-center">Disclaimer</h3>
        <p className="text-center">
          <PiListMagnifyingGlassLight />
          No Data
        </p>
      </div>
      <Footer />
    </>
  );
}
export default Disclaimer;
