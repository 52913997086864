import { PiListMagnifyingGlassLight } from "react-icons/pi";
import Footer from "../Footer/Footer";
import "./StaticPage.css";
import CommonHomeNavbar from "../Navbar/CommonHomeNavbar";
import { Helmet } from "react-helmet";

function Privacy() {
  return (
    <>
      <CommonHomeNavbar />

      <Helmet>
        {/* meta tags */}
        <title>
        Privacy Policy | Masala of India | Indian Restaurant in Seattle
        </title>
        <meta
          name="description"
          content="Discover how our privacy policy ensures the protection of your personal information. Learn about our commitment to safeguarding your privacy today."
        />
        <meta
          name="keywords"
          content="Privacy Policy, Masala Of India, best indian restaurant in  seattle, indian cuisine in seattle, indian food lunch buffet in seattle, indian restaurants near me delivery, online indian food delivery near me, indian restaurants in seattle washington, best indian restaurants in seattle, indian buffet bellevue,indian food buffets, best indian restaurants in bellevu, indian restaurants in seattle"
        />

        {/* OG tags */}
        <meta
          property="og:title"
          content="Masala of India Cuisine Best Indian Restaurant in Seattle, WA 98125"
        />
        <meta
          property="og:description"
          content="Discover how our privacy policy ensures the protection of your personal information. Learn about our commitment to safeguarding your privacy today."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.masalaofindia.com/privacy"
        />
        <meta
          property="og:image"
          content="https://www.masalaofindia.com/static/media/masala-of-india.c5a7d2004e43f4db1721.png"
        />
        <meta property="og:site_name" content="masala of india" />

        {/* Twitter tags */}
        <meta
          name="twitter:title"
          content="Masala of India Cuisine Best Indian Restaurant in Seattle, WA 98125"
        />
        <meta
          name="twitter:description"
          content="Discover how our privacy policy ensures the protection of your personal information. Learn about our commitment to safeguarding your privacy today."
        />
        <meta
          name="twitter:image"
          content="https://www.masalaofindia.com/static/media/masala-of-india.c5a7d2004e43f4db1721.png"
        />
        <meta name="twitter:site" content="masala of india" />
      </Helmet>

      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-header-box">
                <h1 class="text-anime">Privacy Policy</h1>
                <nav>
                  <ol class="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Privacy Policy</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid static-page">
        <h3 className="text-center">Privacy Policy</h3>
        <p className="text-center">
          <PiListMagnifyingGlassLight />
          No Data
        </p>
      </div>
      <Footer />
    </>
  );
}
export default Privacy;
