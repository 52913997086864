import "animate.css";
import { useEffect, useState } from "react";
import GetApiCall from "../../../helper/GetApi";
import Footer from "../../Masala-of-india/Footer/Footer";
import CommonHomeAbout from "../About/About";
import CommonHomeBanner from "../Banner/Banner";
import Catering from "../Catering/Catering";
import CommonHomeDeliveryPartners from "../DeliveryPartners/DeliveryPartners";
import Holiday from "../Holiday/Holiday";
import CommonHomeImageGallery from "../ImageGallery/ImageGallery";
import CommonHomeTestimonial from "../LunchBuffet/LunchBuffet";
import CommonHomeNavbar from "../Navbar/CommonHomeNavbar";
import OfferPopup from "../OfferPopup/OfferPopup";
import Testimonial from "../Testimonial/Testimonial";
import "./CommonHome.css";
import { Helmet } from "react-helmet";

const CommonHome = () => {
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [offerBanner, setOfferBanner] = useState("");

  useEffect(() => {
    GetApiCall.getRequest("getBannerDetails").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (
            obj1.message.filter((data) => data.fld_banner_location == "Offer")
              .length != 0
          ) {
            setOfferBanner(
              obj1.message.filter((data) => data.fld_banner_location == "Offer")
            );
            setOfferModalOpen(true);
          }
        }
      })
    );
  }, []);

  function handleCancel() {
    setOfferModalOpen((prevState) => !prevState);
  }

  return (
    <>
      <div className="masla-of-india-home">
        <Helmet>
          {/* meta tags */}
          <title>
            Masala of India Cuisine Best Indian Restaurant in Seattle, WA 98125
          </title>
          <meta
            name="description"
            content="Masala of India is an Indian restaurant, which offers a variety of different dishes from all over India as well as an Indian buffet at an affordable price."
          />
          <meta
            name="keywords"
            content="Masala Of India, best indian restaurant in  seattle, indian cuisine in seattle, indian food lunch buffet in seattle, indian restaurants near me delivery, online indian food delivery near me, indian restaurants in seattle washington, best indian restaurants in seattle, indian buffet bellevue,indian food buffets, best indian restaurants in bellevu, indian restaurants in seattle"
          />

          {/* OG tags */}
          <meta
            property="og:title"
            content="Masala of India Cuisine Best Indian Restaurant in Seattle, WA 98125"
          />
          <meta
            property="og:description"
            content="Masala of India is an Indian restaurant, which offers a variety of different dishes from all over India as well as an Indian buffet at an affordable price."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.masalaofindia.com/" />
          <meta
            property="og:image"
            content="https://www.masalaofindia.com/static/media/masala-of-india.c5a7d2004e43f4db1721.png"
          />
          <meta property="og:site_name" content="masala of india" />

          {/* Twitter tags */}
          <meta
            name="twitter:title"
            content="Masala of India Cuisine Best Indian Restaurant in Seattle, WA 98125"
          />
          <meta
            name="twitter:description"
            content="Masala of India is an Indian restaurant, which offers a variety of different dishes from all over India as well as an Indian buffet at an affordable price."
          />
          <meta
            name="twitter:image"
            content="https://www.masalaofindia.com/static/media/masala-of-india.c5a7d2004e43f4db1721.png"
          />
          <meta name="twitter:site" content="masala of india" />
        </Helmet>

        <CommonHomeNavbar />
        <CommonHomeBanner />
        <CommonHomeAbout />
        <CommonHomeImageGallery />
        <Holiday />
        <Catering />
        <CommonHomeTestimonial />
        <Testimonial />
        <CommonHomeDeliveryPartners />
        {/* <CommonHomeLocation /> */}
        {offerModalOpen && (
          <OfferPopup onCancel={handleCancel} offerBannerImage={offerBanner} />
        )}
        <Footer />
      </div>
    </>
  );
};

export default CommonHome;
